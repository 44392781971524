const importExcel = [
  {
    path: "/leaseholdMgmt",
    name: 'exportTask',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "导出任务",
      index: "17", // 菜单索引不能重复
      icon: "el-icon-download"
    },
    redirect: "/leaseholdMgmt/exportTask",
    children: [
      {
        path: "/leaseholdMgmt/exportTask",
        name: "importExcel",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "任务列表",
          index: "17-1"
        },
        component: () =>
          import(/* webpackChunkName: "importExcel" */ "@/views/importExcel/importExcel")
      },
    ]
  }
];
export default importExcel;
