const helloPage = [
  {
    path:'/leaseholdMgmt',
    name:'helloPage',
    isMenu: false, // 只有为true的才渲染在菜单上
    meta: {
      title: '海狸科技',
      index: '-999',
      icon:'el-icon-news'
    },
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    redirect: "/leaseholdMgmt/hello",
    children:[
      {
        path:'/leaseholdMgmt/hello',
        name:'hello',
        component: () => import(/* webpackChunkName: "login" */ '@/views/hello/hello.vue'),
        isMenu: false,
        meta: {
          title: '海狸科技',
          index: '-999-1',
        },
      },
    ]
  }
]
export default helloPage;
