const product = [
  {
    path: '/leaseholdMgmt',
    name: 'product',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>',
    },
    meta: {
      title: '产品管理',
      index: '2', // 菜单索引不能重复
      icon: 'el-icon-box',
    },
    redirect: '/leaseholdMgmt/supplier',
    children: [
      {
        path: '/leaseholdMgmt/supplier',
        name: 'supplier',
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: '供应商管理',
          index: '2-1',
        },
        component: () => import(/* webpackChunkName: "supplier" */ '@/views/product/supplier')
      },
      {
        path: '/leaseholdMgmt/addSupplier',
        name: 'addSupplier',
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "addSupplier" */ '@/views/product/addSupplier')
      },
      {
        path: '/leaseholdMgmt/library',
        name: 'library',
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: '产品库管理',
          index: '2-2',
          keepAlive: true,
          isBack: false
        },
        component: () => import(/* webpackChunkName: "library" */ '@/views/product/library')
      },
      {
        path: '/leaseholdMgmt/addProduct',
        name: 'addProduct',
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "addProduct" */ '@/views/product/addProduct')
      },
    ]
  },
]

export default product