// 举例
import http from '../../js/ajax'

const state = {
  userInfo: {},
  menus: [],
}

const mutations = {
  updateUserInfo (states, newValue) {
    states.userInfo = newValue
  },
  updateMenus (states, newValue) {
    states.menus = newValue
  }
}
// const actions = {
//   async login ({ commit }, params) {
//     const res = await http.get('/api/systemUser/login.html', params) // 举例的
//     commit('updateUserInfo', res.data.userName)
//     return Promise.resolve(res) // 在调用页面可以 使用then方法
//   }
// }
export default {
  namespaced: true,
  state,
  mutations,
}
