const user = [
  {
    path: "/leaseholdMgmt",
    name: 'userMgmt',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h90"><router-view></router-view></div>'
    },
    meta: {
      title: "用户管理",
      index: "9", // 菜单索引不能重复
      icon: "el-icon-user"
    },
    redirect: "/leaseholdMgmt/user",
    children: [
      {
        path: "/leaseholdMgmt/user",
        name: "user",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "用户管理",
          index: "9-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/user")
      },
      {
        path: "/leaseholdMgmt/editIdentify",
        name: "editIdentify",
        isMenu: true, // 只有为true的才渲染在菜单上
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/editIdentify")
      },
      {
        path: "/leaseholdMgmt/viewContract",
        name: "viewContract",
        isMenu: true, // 只有为true的才渲染在菜单上
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/viewContract")
      }
    ]
  }
];

export default user;
