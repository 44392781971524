/**
 * 项目环境接口配置
 * @api: 数据接口
 * @static: 静态资源
 */
const origin = location.origin
const pkg = require('../package.json')
const config = {
  dev: {
    // api: 'https://hailixinnengyuan.com',
    api: 'api',
  },
  mock: {
    api: 'https://yapi.lititi.com/mock/23',
  },
  online: {
    api: origin,
    // api:'https://hailixinnengyuan.com/leaseholdMgmt'
  },
  version: `v${pkg.version}`
}
const mock = {
  url: 'http://yapi.mockuai.com/mock/2183/'
}
const imgURL = 'http://code.ywwl.com:8082/store/'
const env = process.env.VUE_APP_API
export default {
  BASE_URL: config[env].api,
  CONFIG: config,
  MOCK: mock,
  isMock: false,
  ImgBASE_URL: imgURL
}
