// 举例
import http from '../../js/ajax'
import {checkHandle} from '@/api/login'

const state = {
  row: {},
  hasHandle: true,
}

const mutations = {
  updateName (states, newValue) {
    states.row = newValue
  }
}
const actions = {
  async login ({ commit }, params) {
    const res = await http.get('/api/systemUser/login.html', params) // 举例的
    commit('updateName', res.data.userName)
    return Promise.resolve(res) // 在调用页面可以 使用then方法
  },
  async checkHandle(context, data) {
    let params
    if(typeof(data)=='string' ) {
      params = {
        checkURI: data
      }
    } else {
      params = data
    }

    try {
      const res =  await checkHandle(params)
      return res.mdata.whetherFlag
    } catch (error) {
      return true
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
