const fundingParty = [
  {
    path: "/leaseholdMgmt",
    name: 'fundingParty',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "资金方管理",
      index: "6", // 菜单索引不能重复
      icon: "el-icon-money"
    },
    redirect: "/leaseholdMgmt/fundingParty",
    children: [
      {
        path: "/leaseholdMgmt/fundingParty",
        name: "fundingParty",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "资金方管理",
          index: "6-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "fundingParty" */ "@/views/fundingParty/fundingParty"
          )
      },
      {
        path: "/leaseholdMgmt/addFundingParty",
        name: "addFundingParty",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "fundingParty" */ "@/views/fundingParty/addFundingParty"
          )
      },
      {
        path: "/leaseholdMgmt/debt",
        name: "debt",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "借据管理",
          index: "6-2",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "debt" */ "@/views/fundingParty/debt")
      },
      {
        path: "/leaseholdMgmt/addDebt",
        name: "addDebt",
        isMenu: false,
        component: () =>
          import(/* webpackChunkName: "debt" */ "@/views/fundingParty/addDebt")
      },
      {
        path: "/leaseholdMgmt/battery",
        name: "battery",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "资金方电池",
          index: "6-3"
        },
        component: () =>
          import(
            /* webpackChunkName: "battery" */ "@/views/fundingParty/battery"
          )
      },
     
    ]
  }
];

export default fundingParty;
