import axios from '../js/ajax'
/**获取图片code */
export const securityImageCode = params => axios.get('/securityImageCode?'+ Math.random(), {params});

/** 发送验证码 */
export const sendMessageCode = params => axios.get('/managementPlatform/sendMessageCode', {params});
/** login */
export const userLoginByPhone = params => axios.get('/managementPlatform/userLoginByPhone', {params});
// 登出
export const loginOut = () => axios.get('/logout', {});
/** 获取用户信息 */
export const getUserInfo = () => axios.get('/getUserInfo', {});
/** 判断用户是否有操作权限 */
export const checkHandle = (params) => axios.get('/electricity/lease/checkUserOperationAuthority',{params});

