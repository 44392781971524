export const filterVariable = (val) => {
  if (val) {
    return val
  }
  return '--'
}

export const changeObj = (list) => {
  const obj = {}
  list.forEach(el => {
    obj[el.value] = el.label
  })
  return obj
}