import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ElementUI from "element-ui";
import VueAmap from 'vue-amap'

import 'element-ui/lib/theme-chalk/index.css'
import "./style/reset.css";
import "./style/common.css";



import { changeObj } from './js/util'
import {timeToDate , handleNull} from '@/utils/date-format';

// 引入了axios
import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$changeObj = changeObj;

Vue.filter('timeToDate', timeToDate)
Vue.filter('handleNull', handleNull)
//
Vue.directive('money',{
  bind(el){
      let inp = el.getElementsByTagName('input')[0];
      inp.oninput = (val)=>{
        inp.value = inp.value.replace(/[^\d.]/g,'')//判断是数字
        // let arr = inp.value.split('.');
        // let fixLen = arr[1];//小数部分
        // if(fixLen && fixLen.length >= 2){//有小数点，小数点后>2位
        //   let sliceTwo = fixLen.slice(0,2);
        //   inp.value = `${arr[0]}.${sliceTwo}`
        // }
      }
  }
})
Vue.directive('number',{
  bind(el){
      let inp = el.getElementsByTagName('input')[0];
      inp.oninput = (val)=>{
          inp.value = inp.value.replace(/[^\d]/g,'');   
          console.log(inp.value,'inp.value');
      }
  }
})

VueAmap.initAMapApiLoader({
  key:"35dbe243d669f8fbc33cd46defb43d6d",
  plugin:[
      "AMap.Autocomplete",
      "AMap.PlaceSearch",
      "AMap.Scale",
      "AMap.OverView",
      "AMap.ToolBar",
      "AMap.MapType",
      "AMap.PolyEditor",
      "AMap.CircleEditor",
      "AMap.Geolocation",
      "AMap.Geocoder",
      "AMap.AMapUI"
  ],
  
})
Vue.use(VueAmap)

// router.beforeEach((to, from, next) => {
//   console.log(to,'to');
//   if(to.path == '/leaseholdMgmt/login'){
//     next();
//   }else{
//     axios.get()
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
