const agent = [
  {
    path: '/leaseholdMgmt',
    name: 'agent',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>',
    },
    meta: {
      title: '代理商管理',
      index: '8', // 菜单索引不能重复
      icon: 'el-icon-user-solid',
    },
    children: [
      {
        path: '/leaseholdMgmt/agent',
        name: 'agent',
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: '代理商管理',
          index: '8-1',
          keepAlive: true,
          isBack: false
        },
        component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/agent')
      },
      {
        path: '/leaseholdMgmt/addAgent',
        name: 'addAgent',
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/addAgent')
      },
      {
        path: '/leaseholdMgmt/addShop',
        name: 'addShop',
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/addShop')
      },
      {
        path: '/leaseholdMgmt/batteryStock',
        name: 'batteryStock',
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: '电池库存管理',
          index: '8-2',
        },
        component: () => import(/* webpackChunkName: "batteryStock" */ '@/views/agent/batteryStock')
      },
    ]
  },
]

export default agent