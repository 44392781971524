import Vue from 'vue'
import Router from 'vue-router'
import defaultRoute from './default'
// import utils from '../utils'
// import store from '../store'
import MainRoute from './main'
// import main from '../store/modules/main'

Vue.use(Router)

// let MainRoute = []
// MainRoute.push({
//   path: '/',
//   name: 'error',
//   meta: {
//     requireAuth: true
//   },
//   redirect: '/',
//   component: () => import('@/views/main.vue'),
//   children: [
//     {
//       path: '*',
//       name: '403',
//       component: () =>
//         import(/* webpackChunkName: "login" */ '@/views/errorPage/403.vue')
//     }
//   ]
// })

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let router = new Router({
  mode: 'history',
  routes: [...defaultRoute, ...MainRoute]
})

export default router
