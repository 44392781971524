const facility = [
  {
    path: "/leaseholdMgmt",
    name: "facility",
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "设备管理",
      index: "1", // 菜单索引不能重复
      icon: "el-icon-takeaway-box"
    },
    redirect: "/leaseholdMgmt/newAddBatteryList",
    children: [
      {
        path: "/leaseholdMgmt/newAddBatteryList",
        name: "newAddBatteryList",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "新增电池列表",
          index: "1-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "newAddBatteryList" */ "@/views/facility/newAddBatteryList"
          )
      },
      {
        path: "/leaseholdMgmt/addNewBattery",
        name: "addNewBattery",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "addNewBattery" */ "@/views/facility/addNewBattery"
          )
      },
      {
        path: "/leaseholdMgmt/batteryView",
        name: "batteryView",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "batteryRemove" */ "@/views/facility/batteryView"
          )
      },
      {
        path: "/leaseholdMgmt/batteryRemove",
        name: "batteryRemove",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "电池出库",
          index: "1-2",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "batteryRemove" */ "@/views/facility/batteryRemove"
          )
      },
      {
        path: "/leaseholdMgmt/batteryList",
        name: "batteryList",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "电池列表",
          index: "1-3",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "batteryList" */ "@/views/facility/batteryList"
          )
      },
      {
        path: "/leaseholdMgmt/faultBatteryList",
        name: "faultBatteryList",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "故障电池",
          index: "1-4"
        },
        component: () =>
          import(
            /* webpackChunkName: "batteryList" */ "@/views/facility/faultBatteryList"
          )
      },
      {
        path: "/leaseholdMgmt/afterSaleBatteryList",
        name: "afterSaleBatteryList",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "售后电池",
          index: "1-5",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "batteryList" */ "@/views/facility/afterSaleBatteryList"
          )
      },
      {
        path: "/leaseholdMgmt/batteryTrack",
        name: "batteryTrack",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "batteryTrack" */ "@/views/facility/batteryTrack")
      },
      {
        path: "/leaseholdMgmt/bmsHistory",
        name: "bmsHistory",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () => import(/* webpackChunkName: "bmsHistory" */ "@/views/facility/bmsHistory")
      },
      {
        path: "/leaseholdMgmt/cardMessage",
        name: "cardMessage",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "物联网卡信息",
          index: "1-7"
        },
        component: () =>
          import(
            /* webpackChunkName: "cardMessage" */ "@/views/facility/cardMessage"
          )
      },
      {
        path: "/leaseholdMgmt/mileage",
        name: "mileage",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "里程统计",
          index: "1-8"
        },
        component: () =>
          import(
            /* webpackChunkName: "mileage" */ "@/views/facility/mileage"
          )
      },
      {
        path: "/leaseholdMgmt/electricVehicle",
        name: "electricVehicle",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "电动车管理",
          index: "1-9",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "electricVehicle" */ "@/views/facility/electricVehicle"
          )
      },
      {
        path: "/leaseholdMgmt/addNewVehicle",
        name: "addNewVehicle",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "新增电动车",
          index: "1-10"
        },
        component: () =>
          import(
            /* webpackChunkName: "addNewVehicle" */ "@/views/facility/addNewVehicle"
          )
      },
    ]
  }
];

export default facility;
