const cash = [
  {
    path: "/leaseholdMgmt",
    name: 'cash',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      icon: "el-icon-bank-card",
      title: "资金管理",
      index: "4" // 菜单索引不能重复
    },
    redirect: "/leaseholdMgmt/bill",
    children: [
      {
        path: "/leaseholdMgmt/bill",
        name: "bill",
        isMenu: false, // 只有为true的才渲染在菜单上
        meta: {
          title: "对账单管理",
          index: "4-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "bill" */ "@/views/cash/bill")
      },
      {
        path: "/leaseholdMgmt/viewBill",
        name: "viewBill",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(/* webpackChunkName: "bill" */ "@/views/cash/viewBill")
      },
      {
        path: "/leaseholdMgmt/subBill",
        name: "subBill",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "分账关系管理",
          index: "4-2",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "subBill" */ "@/views/cash/subBill")
      },
      {
        path: "/leaseholdMgmt/addSubBill",
        name: "addSubBill",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(/* webpackChunkName: "bill" */ "@/views/cash/addSubBill")
      },
      {
        path:'/leaseholdMgmt/settlePage',
        name:'settlePage',
        component: () => import(/* webpackChunkName: "settlePage" */ '@/views/settlePage/settlePage'),
        isMenu: true,
        meta: {
          title: '结算管理',
          index: '4-3',
        },
      },
      {
        path:'/leaseholdMgmt/repayment',
        name:'repayment',
        component: () => import(/* webpackChunkName: "payBack" */ '@/views/settlePage/repayment'),
        isMenu: true,
        meta: {
          title: '还款管理',
          index: '4-4',
        },
      },
    ]
  }
];

export default cash;
