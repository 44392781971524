const goods = [
  {
    path: "/leaseholdMgmt",
    name: 'goods',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "商品管理",
      index: "5", // 菜单索引不能重复
      icon: "el-icon-goods"
    },
    redirect: "/leaseholdMgmt/goods",
    children: [
      {
        path: "/leaseholdMgmt/goods",
        name: "goods",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "商品管理",
          index: "5-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "goods" */ "@/views/goods/goods")
      },
      {
        path: "/leaseholdMgmt/addGoods",
        name: "addGoods",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(/* webpackChunkName: "goods" */ "@/views/goods/addGoods")
      },
      {
        path: "/leaseholdMgmt/insurance",
        name: "insurance",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "新增保险",
          index: "5-2",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "insurance" */ "@/views/goods/insurance")
      },
      {
        path: "/leaseholdMgmt/addInsurance",
        name: "addInsurance",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "insurance" */ "@/views/goods/addInsurance"
          )
      }
    ]
  }
];

export default goods;
