import axios from "axios";
import { Message, Loading } from "element-ui";
import qs from "qs";
import config from "../config";
let baseURL = config.isMock ? config.MOCK.url : config.BASE_URL; // 请求接口地址
axios.defaults.baseURL = baseURL;
// 获取签名
export const Axios = axios.create({
  timeout: 20000,
  // responseType: 'json',
  withCredentials: true, // 是否允许带cookie这些
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    "mgmtType": 1,
  }
});
// let loading;
// const beginLoading = () => {
//   loading = Loading.service({
//     lock: true,
//     text: "Loading"
//   });
// };
// const closeLoading = () => {
//   loading.close();
// };
// 添加一个请求拦截器
Axios.interceptors.request.use(
  request => {
    // if (request.loading) {
    //   beginLoading();
    // }
    if (request.data && typeof request.data == Object) {
      for (let key in  request.data) {
        if (!request.data[key]) {
          delete request.data[key]
        }
      }
    }
    if (request.params && typeof request.params == Object) {
      for (let key in  request.params) {
        if (!request.params[key]) {
          delete request.params[key]
        }
      }
    }
    if (request.headers['Content-Type'] != 'application/json' && request.method == 'post') {
      request.data = qs.stringify(request.data);
    }
    if (request.params && request.params.beginIndex) {
      const {beginIndex, pageSize} = request.params
      request.params.beginIndex = beginIndex * pageSize
    }
    return request;
  },
  err => Promise.reject(err)
);
// 响应拦截器
Axios.interceptors.response.use(
  response => {
    //** 兼容图片验证码 */
    if (typeof response.data == "string") {
      return Promise.resolve(response.data);
    }
    const { result } = response.data;
    const mdata = response.data.mdata || {};
    // loading && closeLoading();
    if (result == 1) {
        return Promise.resolve(response.data);
    } else if (result == 0 && mdata.errorCode == 0) {
      Message.error(response.data.message);
      if (location.pathname !== "/leaseholdMgmt/login") {
        window.location.href = "/leaseholdMgmt/login";
      }
    } else if (result == 2) {

      this.$message({
        showClose: true,
        message: response.data.message,
        type: "error",
        duration: 5000
      });
    } else {
      if(response.config.url != "/managementPlatform/lease/addOrUpdateCommodity"){
        Message.error(response.data.message);
      }
      return Promise.reject(response.data);
    }
  },
  err => {
    Message.error("网络异常，请稍后重试！");
    // loading && closeLoading();
    return Promise.reject(err);
  }
);

const postJson = (url,data)=>{
    return new Promise((resolve,reject)=>{
      Axios({
        url,
        method: 'post',
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
    })
}
Axios.postJson = postJson
export default Axios;
