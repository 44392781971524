import facility from './facility'
import product from './product'
import order from './order'
import cash from './cash'
import goods from './goods'
import fundingParty from './fundingParty'
import marketing from './marketing'
import agent from './agent'
import user from './user'
import jurisdiction from './jurisdiction'
import dataStatement from './dataStatement'
import importExcel from './importExcel'
import helloPage from "./hello";

// import closeAccount from './closeAccount'

const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {
      requireAuth: true
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/main.vue'),
    children: [
      ...helloPage,
      // ...closeAccount,
      ...dataStatement,
      ...facility,
      ...product,
      ...order,
      ...cash,
      ...goods,
      ...fundingParty,
      ...marketing,
      ...agent,
      ...user,
      ...jurisdiction,
      ...importExcel,
    ]
  }
]
export default routes
