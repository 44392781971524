const marketing = [
  {
    path: "/leaseholdMgmt",
    name: 'marketing',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "营销管理",
      index: "7", // 菜单索引不能重复
      icon: "el-icon-tickets"
    },
    redirect: "/leaseholdMgmt/recharge",
    children: [
      {
        path: "/leaseholdMgmt/recharge",
        name: "recharge",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "充值赠送",
          index: "7-1"
        },
        component: () =>
          import(
            /* webpackChunkName: "recharge" */ "@/views/marketing/recharge"
          )
      },
      {
        path: "/leaseholdMgmt/addRecharge",
        name: "addRecharge",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "recharge" */ "@/views/marketing/addRecharge"
          )
      },
      {
        path: "/leaseholdMgmt/activity",
        name: "activity",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "活动弹窗",
          index: "7-2",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(
            /* webpackChunkName: "activity" */ "@/views/marketing/activity"
          )
      },
      {
        path: "/leaseholdMgmt/addActivity",
        name: "addActivity",
        isMenu: false, // 只有为true的才渲染在菜单上
        component: () =>
          import(
            /* webpackChunkName: "recharge" */ "@/views/marketing/addActivity"
          )
      }
    ]
  }
];

export default marketing;
