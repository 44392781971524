const routes = [
  {
    path: '/leaseholdMgmt/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/leaseholdMgmt/fundingPartyScreen',
    name: 'special',
    component: () => import(/* webpackChunkName: "special" */ '@/views/special/index.vue')
  },
  { path: '*', redirect: { name: 'login' }},
]

export default routes
