const jurisdiction = [
  {
    path: '/leaseholdMgmt',
    name: 'jurisdiction',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>',
    },
    meta: {
      title: '权限管理',
      index: '10', // 菜单索引不能重复
      icon: 'el-icon-tickets',
    },
    redirect: '/leaseholdMgmt/menu',
    children: [
      {
        path: '/leaseholdMgmt/menu',
        name: 'menu',
        isMenu: false, // 只有为true的才渲染在菜单上
        meta: {
          title: '应用菜单管理',
          index: '10-1',
        },
        component: () => import(/* webpackChunkName: "menu" */ '@/views/jurisdiction/menu')
      },
      {
        path: '/leaseholdMgmt/service',
        name: 'service',
        isMenu: false, // 只有为true的才渲染在菜单上
        meta: {
          title: '服务资源',
          index: '10-2',
        },
        component: () => import(/* webpackChunkName: "service" */ '@/views/jurisdiction/service')
      },
      {
        path: '/leaseholdMgmt/role',
        name: 'role',
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: '权限方案',
          index: '10-3',
        },
        component: () => import(/* webpackChunkName: "role" */ '@/views/jurisdiction/role')
      },
    
      // {
      //   path: '/leaseholdMgmt/roleUser',
      //   name: 'user',
      //   isMenu: false, // 只有为true的才渲染在菜单上
      //   meta: {
      //     title: '用户权限',
      //     index: '10-4',
      //   },
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/jurisdiction/user')
      // },
      // {
      //   path: "/leaseholdMgmt/user",
      //   name: "user",
      //   isMenu: true, // 只有为true的才渲染在菜单上
      //   meta: {
      //     title: "用户管理",
      //     index: "9-1"
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "user" */ "@/views/user/user")
      // },
      // {
      //   path: "/leaseholdMgmt/editIdentify",
      //   name: "editIdentify",
      //   isMenu: false, // 只有为true的才渲染在菜单上
      //   component: () =>
      //     import(/* webpackChunkName: "user" */ "@/views/user/editIdentify")
      // },
      // {
      //   path: "/leaseholdMgmt/viewContract",
      //   name: "viewContract",
      //   isMenu: false, // 只有为true的才渲染在菜单上
      //   component: () =>
      //     import(/* webpackChunkName: "user" */ "@/views/user/viewContract")
      // }
    ]
  },
]

export default jurisdiction