const order = [
  {
    path: "/leaseholdMgmt",
    name: 'order',
    isMenu: true, // 只有为true的才渲染在菜单上
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    meta: {
      title: "订单管理",
      index: "3", // 菜单索引不能重复
      icon: "el-icon-tickets"
    },
    redirect: "/leaseholdMgmt/rent",
    children: [
      {
        path: "/leaseholdMgmt/rent",
        name: "rent",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "租电订单",
          index: "3-1",
          keepAlive: true,
          isBack: false
        },
        component: () =>
          import(/* webpackChunkName: "rent" */ "@/views/order/rent"),
      },
      // {
      //   path: "/leaseholdMgmt/viewRent",
      //   name: "viewRent",
      //   isMenu: false, // 只有为true的才渲染在菜单上
      //   component: () =>
      //     import(/* webpackChunkName: "rent" */ "@/views/order/viewRent")
      // },
      {
        path: "/leaseholdMgmt/cashRefund",
        name: "cashRefund",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "押金退款",
          index: "3-2"
        },
        component: () =>
          import(
            /* webpackChunkName: "cashRefund" */ "@/views/order/cashRefund"
          )
      },
      {
        path: "/leaseholdMgmt/batteryChange",
        name: "batteryChange",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "电池更换记录",
          index: "3-3"
        },
        component: () =>
          import(
            /* webpackChunkName: "batteryChange" */ "@/views/order/batteryChange"
          )
      },
      {
        path: "/leaseholdMgmt/insureManage",
        name: "insureManage",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "保险管理",
          index: "3-4"
        },
        component: () =>
          import(
            /* webpackChunkName: "insureManage" */ "@/views/order/insureManage"
          )
      },
      {
        path: "/leaseholdMgmt/orderDetail",
        name: "orderDetail",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "订单详情",
          index: "3-5"
        },
        component: () =>
          import(
            /* webpackChunkName: "orderDetail" */ "@/views/order/orderDetail"
          )
      },

    ]
  }
];

export default order;
