const dataStatement = [
  {
    path:'/leaseholdMgmt',
    name:'dataStatement',
    isMenu: true, // 只有为true的才渲染在菜单上
    meta: {
      title: '数据统计',
      index: '11',
      icon:'el-icon-news'
    },
    component: {
      template: '<div class="h100"><router-view></router-view></div>'
    },
    redirect: "/leaseholdMgmt/changeEleCharts",
    children:[
      // {
      //   path:'/leaseholdMgmt/changeEleCharts',
      //   name:'changeEleCharts',
      //   component: () => import(/* webpackChunkName: "changeEleCharts" */ '@/views/dataStatement/changeEleCharts'),
      //   isMenu: true,
      //   meta: {
      //     title: '运营大屏（换电）',
      //     index: '11-1',
      //   },
      // },
      {
        path:'/leaseholdMgmt/rentEleCharts',
        name:'rentEleCharts',
        component: () => import(/* webpackChunkName: "rentEleCharts" */ '@/views/dataStatement/rentEleCharts'),
        isMenu: true,
        meta: {
          title: '运营大屏',
          index: '11-2',
        },
      },
      {
        path:'/leaseholdMgmt/status',
        name:'status',
        component: () => import(/* webpackChunkName: "status" */ '@/views/dataStatement/status'),
        isMenu: true,
        meta: {
          title: '状态统计',
          index: '11-3',
        },
      },
      {
        path:'/leaseholdMgmt/warn',
        name:'warn',
        component: () => import(/* webpackChunkName: "warn" */ '@/views/dataStatement/warn'),
        isMenu: true,
        meta: {
          title: '告警统计',
          index: '11-4',
        },
      },
      {
        path:'/leaseholdMgmt/income',
        name:'income',
        component: () => import(/* webpackChunkName: "income" */ '@/views/dataStatement/income'),
        isMenu: true,
        meta: {
          title: '收益统计',
          index: '11-5',
        },
      },
      {
        path:'/leaseholdMgmt/incomeCollect',
        name:'incomeCollect',
        component: () => import(/* webpackChunkName: "incomeCollect" */ '@/views/dataStatement/incomeCollect'),
        isMenu: true,
        meta: {
          title: '收益汇总',
          index: '11-6',
        },
      },
      {
        path:'/leaseholdMgmt/positionTime',
        name:'positionTime',
        component: () => import(/* webpackChunkName: "positionTime" */ '@/views/dataStatement/positionTime'),
        isMenu: true,
        meta: {
          title: '定位时间',
          index: '11-7',
        },
      },
      {
        path:'/leaseholdMgmt/operation',
        name:'operation',
        component: () => import(/* webpackChunkName: "operation" */ '@/views/dataStatement/operation'),
        isMenu: true,
        meta: {
          title: '运营数据报告',
          index: '11-8',
        },
      },
      {
        path: "/leaseholdMgmt/fundingPartyBigScreen",
        name: "fundingPartyBigScreen",
        isMenu: true, // 只有为true的才渲染在菜单上
        meta: {
          title: "资金方大屏",
          index: "11-9"
        },
        component: () =>
          import(
            /* webpackChunkName: "fundingPartyBigScreen" */ "@/views/facility/fundingPartyBigScreen"
          )
      },
     
      
    ]
  }
]
export default dataStatement;